<template>
  <v-stepper v-model="step" vertical class="elevation-0">
    <v-stepper-step :complete="!!assumedRole" step="1">
      Account Credentials
    </v-stepper-step>

    <AuthStepBasic
      :access-token.sync="accessToken"
      :secret-token.sync="secretToken"
      :region.sync="region"
      @auth-basic="handleBasic"
      @goto-mfa="handleGotoMfa"
      @show-help="showHelp = true"
    />

    <v-stepper-step :complete="!!assumedRole && assumedRole.from_mfa" step="2">
      MFA Authorization
    </v-stepper-step>

    <AuthStepMFA
      :mfa-token.sync="mfaToken"
      :mfa-serial.sync="mfaSerial"
      @auth-mfa="handleProceedMFA"
      @exit-mfa="handleCancelMfa"
    />

    <v-stepper-step step="3"> Browse Bucket </v-stepper-step>

    <FileList
      :auth="assumedRole"
      :files="files"
      @exit-list="handleExitList"
      @got-files="gotFiles"
      @load-bucket="loadBucket"
    />
    <v-overlay :opacity="50" absolute="absolute" :value="showHelp">
      <div class="text-center">
        <p>
          <span>
            For installation instructions and further information, check here:
          </span>
          <v-btn
            target="_blank"
            href="https://github.com/mitre/heimdall2#aws-s3"
            text
            color="info"
            px-0
          >
            <v-icon pr-2>mdi-github-circle</v-icon>
            S3 Configuration
          </v-btn>
        </p>
        <v-btn color="info" @click="showHelp = false"> Close </v-btn>
      </div>
    </v-overlay>
  </v-stepper>
</template>

<script lang="ts">
import {_Object, ListObjectsV2Command, S3Client} from '@aws-sdk/client-s3';
import Vue from 'vue';
import Component from 'vue-class-component';
import AuthStepBasic from '@/components/global/upload_tabs/aws/AuthStepBasic.vue';
import AuthStepMFA from '@/components/global/upload_tabs/aws/AuthStepMFA.vue';
import FileList from '@/components/global/upload_tabs/aws/FileList.vue';
import {FileID} from '@/store/report_intake';
import {SnackbarModule} from '@/store/snackbar';
import {
  Auth,
  AUTH_DURATION,
  getSessionToken,
  MFAInfo,
  transcribeError
} from '@/utilities/aws_util';
import {LocalStorageVal} from '@/utilities/helper_util';
import {requireFieldRule} from '@/utilities/upload_util';

/** The cached session info */
const localSessionInformation = new LocalStorageVal<Auth | null>(
  'aws_session_info'
);

/**
 * Uploads data to the store with unique IDs asynchronously as soon as data is entered.
 * Emits "got-files" with a list of the unique_ids of the loaded files.
 */
@Component({
  components: {
    AuthStepBasic,
    AuthStepMFA,
    FileList
  }
})
export default class S3Reader extends Vue {
  // Form required field rule
  reqRule = requireFieldRule;

  /** Passed from step 1 to step 2 (MFA) if necessary */
  /** State of all globally relevant fields */
  accessToken = '';
  secretToken = '';
  region = '';
  mfaSerial = '';
  mfaToken = '';
  showHelp = false;

  /** Our session information, generated by AWS STS */
  assumedRole: Auth | null = null;

  /** Current step */
  step = 1;

  /** Currently loaded file list from bucket */
  files: _Object[] = [];

  /**
   * Handle a basic login.
   * Gets a session token
   */
  handleBasic() {
    // Attempt to assume role based on if we've determined 2fa necessary
    getSessionToken(
      this.accessToken,
      this.secretToken,
      this.region || 'us-east-1',
      AUTH_DURATION
    ).then(
      // Success of get session token - now need to determine if MFA necessary
      (success) => {
        this.assumedRole = success;
        this.step = 3;
      },

      // Failure of initial get session token: want to set error normally
      (failure) => {
        this.handleError(failure);
      }
    );
  }

  /** If the user tries to login by going to MFA, first check that the account is valid */
  handleGotoMfa() {
    // Attempt to assume role based on if we've determined 2fa necessary
    // Don't need the duration to be very long
    getSessionToken(
      this.accessToken,
      this.secretToken,
      this.region || 'us-east-1',
      10
    ).then(
      // Success of get session token - now need to determine if MFA necessary
      () => {
        this.step = 2;
      },

      // Failure of initial get session token: want to set error normally
      (failure) => {
        this.handleError(failure);
      }
    );
  }

  handleCancelMfa() {
    this.step = 1;
    this.mfaToken = '';
    this.assumedRole = null; // Just in case
  }

  handleExitList() {
    this.step = 1;
    this.mfaToken = '';
    this.assumedRole = null;
    this.files = []; // Just in case
  }

  /** Handle an MFA login.
   * Determine whether further action is necessary
   */
  handleProceedMFA() {
    // Build our mfa params
    const mfa: MFAInfo = {
      SerialNumber: this.mfaSerial || null,
      TokenCode: this.mfaToken
    };

    // Attempt to assume role based on if we've determined 2fa necessary
    getSessionToken(
      this.accessToken,
      this.secretToken,
      this.region || 'us-east-1',
      AUTH_DURATION,
      mfa
    ).then(
      (success) => {
        // Keep them
        this.assumedRole = success;
        this.step = 3;
      },
      (failure) => {
        this.handleError(failure);
      }
    );
  }

  /** On mount, try to look up stored auth info */
  mounted() {
    // Load our session, if there is one
    this.assumedRole = localSessionInformation.getDefault(null);
    if (this.assumedRole) {
      this.step = 3;
    }
  }

  /** Attempt to load.
   * Basically just wraps fetch_files with error handling
   */
  async loadBucket(name: string) {
    const s3 = new S3Client({
      credentials: this.assumedRole?.creds,
      region: this.assumedRole?.region || 'us-east-1'
    });
    try {
      const response = await s3.send(
        new ListObjectsV2Command({
          Bucket: name,
          MaxKeys: 100
        })
      );
      this.files = response.Contents || [];
    } catch (failure) {
      this.handleError(failure);
    }
  }

  /** Save the current credentials to local storage */
  // CURRENTLY UNUSED CODE - no logout button yet to reset it
  saveCreds() {
    localSessionInformation.set(this.assumedRole);
  }

  /** Callback to handle an AWS error.
   * Sets shown error.
   */
  handleError(error: {name: string; message: string}): void {
    const formattedError = transcribeError(error);
    // Toast whatever error we got
    SnackbarModule.failure(formattedError);
  }

  /** Callback on got files */
  gotFiles(files: Array<FileID>) {
    this.$emit('got-files', files);
  }
}
</script>
