import { render, staticRenderFns } from "./Results.vue?vue&type=template&id=4b0c6fd2&scoped=true"
import script from "./Results.vue?vue&type=script&lang=ts"
export * from "./Results.vue?vue&type=script&lang=ts"
import style0 from "./Results.vue?vue&type=style&index=0&id=4b0c6fd2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0c6fd2",
  null
  
)

export default component.exports