var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pb-1",attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"font-weight-bold",attrs:{"cols":"12"}},[_c('v-card',[_c('v-tabs',{attrs:{"fixed-tabs":"","show-arrows":""},on:{"change":_vm.tab_change},model:{value:(_vm.localTab),callback:function ($$v) {_vm.localTab=$$v},expression:"localTab"}},[_c('v-tab',{attrs:{"href":"#tab-test"}},[_vm._v(" Test ")]),_c('v-tab',{attrs:{"href":"#tab-details"}},[_vm._v(" Details ")]),_c('v-tab',{attrs:{"href":"#tab-code"}},[_vm._v(" Code ")]),_c('v-tab-item',{attrs:{"value":"tab-test"}},[_c('div',{staticClass:"pa-4"},[(
                _vm.caveat ||
                _vm.justification ||
                _vm.rationale ||
                _vm.comments ||
                _vm.errorMessage
              )?_c('div',[(_vm.errorMessage)?_c('div',{staticClass:"mb-2"},[_c('v-btn',{staticClass:"unclickable-button mr-3",attrs:{"elevation":"2","depressed":""}},[_vm._v(" Error ")]),_c('span',[_vm._v(" "+_vm._s(_vm.errorMessage)+" "),_c('br')])],1):_vm._e(),(_vm.caveat)?_c('span',[_vm._v("Caveat: "+_vm._s(_vm.caveat)),_c('br')]):_vm._e(),(_vm.justification)?_c('span',[_vm._v(" Justification: "+_vm._s(_vm.justification)+" "),_c('br')]):_vm._e(),(_vm.rationale)?_c('span',[_vm._v("Rationale: "+_vm._s(_vm.rationale)),_c('br')]):_vm._e(),(_vm.comments)?_c('span',[_vm._v("Comments: "+_vm._s(_vm.comments)),_c('br')]):_vm._e(),_c('v-divider'),_c('br')],1):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.sanitize_html(_vm.main_desc))}})]),_vm._l((_vm.control.root.hdf.segments),function(result,index){return _c('ControlRowCol',{key:'col' + index,class:_vm.zebra(index),attrs:{"result":result,"status-code":result.status}})})],2),_c('v-tab-item',{attrs:{"value":"tab-details"}},[_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.details),function(detail,index){return [_c('v-row',{key:'tab' + index,class:_vm.zebra(index)},[_c('v-col',{class:detail.class,attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(detail.name)+":")]),_c('h4',[_c('pre',{staticClass:"mono",domProps:{"innerHTML":_vm._s(_vm.sanitize_html(detail.value))}})])]),_c('v-divider')],1)]})],2)],1),_c('v-tab-item',{attrs:{"value":"tab-code"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('prism',{attrs:{"language":_vm.language}},[_vm._v(_vm._s(_vm.control.full_code))])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }